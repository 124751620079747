import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  private readonly localStorage: Storage | null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.localStorage = isPlatformBrowser(this.platformId)
      ? window.localStorage
      : null;
  }

  getItem(key: string): any {
    if (this.localStorage) {
      return this.localStorage.getItem(key);
    }
    return null;
  }

  setItem(key: string, value: string): void {
    if (this.localStorage) {
      this.localStorage.setItem(key, value);
    }
  }

  removeItem(key: string): void {
    if (this.localStorage) {
      this.localStorage.removeItem(key);
    }
  }

  clear(): void {
    if (this.localStorage) {
      this.localStorage.clear();
    }
  }
}
