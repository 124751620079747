import { Injectable, computed, inject, signal } from '@angular/core';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private localStorageService = inject(LocalstorageService);

  language = signal(this.localStorageService.getItem('language') ?? 'fr');
  languageName = computed(() => {
    if (this.language() === 'fr') {
      return 'English';
    } else {
      return 'Français';
    }
  });

  switchLanguage(lang: string) {
    this.localStorageService.setItem('language', lang);
    this.language.set(lang);
    return this.language;
  }
}
